@import "~styles";

.wrapper {
  background-color: #1a1a1a;
  color: #fff;
  border-radius: 8px;
  max-width: 440px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0;
  line-height: 1.4;
}

.subtitle {
  font-size: 14px;
  color: #ccc;
  margin-bottom: 20px;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
  padding: 5px;
}

.input {
  flex: 1;
  border: none;
  padding: 12px 15px;
  font-size: 16px;
  border-radius: 30px 0 0 30px;
  outline: none;
  color: #333;

  &::placeholder {
    color: #999;
  }

  @include mobileL {
    width: 100%;
    text-overflow: ellipsis;
  }
}

.button {
  background-color: #e60012;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background-color: #cc000f;
  }
}

.error {
  font-size: 14px;
  color: #d31a22;
  margin-top: 10px;
}

.terms {
  font-size: 12px;
  color: #ccc;
  margin-top: 15px;
  line-height: 1.4;
}

.link {
  color: #fff;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
