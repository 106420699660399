@import "~styles";

.footer {
  background-color: #1a1a1a;
  padding: 0px 15px 30px;
  display: flex;
  justify-content: space-around;
}
.footerInner {
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 80%;

  > * {
    margin-bottom: 2em;
    flex: 1 0 40%;

    @include mobileL {
      flex: auto;
      width: 100%;
    }
  }

  .links-sharing-container {
    padding-top: 30px;
  }

  img {
    height: 33px;
    width: 89px;
  }

  .desktopLogo {
    display: none;
  }

  @media (min-width: 1000px) {
    justify-content: space-around;
    padding: 40px 180px 40px;

    #footer-newsletter {
      width: auto;
    }

    div[class$="links-sharing-container"] {
      display: flex;
    }
    .desktopLogo {
      display: flex;
    }

    .mobileLogo {
      display: none;
    }
  }
}
.legalText {
  align-self: flex-end;
}
.sharingLinks {
  align-self: flex-end;
}
.logoContainer {
  flex: 1 0 100%;
}
